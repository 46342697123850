import { environmentBase, mergeEnviroments } from './environment.base';

export const environment = {
	production: true,
	...mergeEnviroments(environmentBase, {}),
	URL_LOGIN: 'https://dev-login.sintad.net.pe/#/auth/login',
	URL_HOME: 'https://dev-login.sintad.net.pe/#/home',
	HOST_SECURITY: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-security/api/security',
	HOST_GUIA_REMISION: `https://api-cluster.sumax.pe/dev/sumax-erp-backend-guia-remision/api`,
	HOST_TRANSPORTE: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-transporte/api',
	HOST_CONFIG: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-config/api',
	HOST_MAESTROS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-maestros/api',
	HOST_MAESTROS_SYNC: 'https://api-cluster-sistemas-internos.sumax.pe/dev/sumax-erp-backend-maestros-sync/api',
	HOST_COMPRAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-compras/api',
	HOST_VENTAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-ventas/api',
	HOST_BANCOS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-bancos/api',
	HOST_INFORMACION_OPERATIVA: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-informacion-operativa/api',
	HOST_DMS: 'https://api-cluster-dms.sumax.pe/dev/sumax-dms',
	HOST_REPORTES: `https://api-cluster.sumax.pe/dev/sumax-erp-backend-reportes/api`,
	HOST_CONTROL_CLIENTES: `https://api-cluster-sistemas-internos.sumax.pe/dev/sumax-erp-backend-control-clientes/api`,
	HOST_DOCTEL: `https://api-cluster.doctel.com.pe/qa/doctel/api`,
};

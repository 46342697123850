import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root',
})
export class CookiesService {
	constructor(private cookieService: CookieService) {}

	setItem(key: string, value: string): void {
		this.cookieService.set(key, value, undefined, '/', environment.production ? '.sintad.net.pe' : 'localhost', true);
	}

	getItem(key: string): string {
		return this.cookieService.get(key);
	}

	removeItem(key: string): void {
		this.cookieService.delete(key, '/', environment.production ? '.sintad.net.pe' : 'localhost', true);
	}

	checkItem(key: string): boolean {
		return this.cookieService.check(key);
	}

	getBearerAuthorization(key: 'idToken' | 'maestroSyncToken'): string {
		const userToken = this.getItem(environment[key]);
		const bearer = 'Bearer ' + userToken;
		return bearer;
	}
}

import { IMqttServiceOptions } from 'ngx-mqtt';

const ambienteLocal = 'dev'; // dev - qa
export const environmentBase = {
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	idToken: 'TokenID',
	su: 'su',
	expires_in: 'expires_in',
	sessionId: 'SessionID',
	refreshToken: 'TokenRefresh',
	maestroSyncToken: 'TokenMaestroSync',
	username: 'username',
	notifications: 'notifications',
	searchTbEntidadDto: {
		idTipoDocumento: 'idTipoDocumento',
		razonSocial: 'razonSocial',
		nroDocumento: 'nroDocumento',
	},
	COD_SISTEMA: 'SGR',
	MQTTCONFIG: {
		hostname: 'b-eb7e7655-db2c-471e-b330-9723b6344494-1.mq.us-east-1.amazonaws.com',
		port: 61619,
		username: 'admin',
		password: 'scmj010400*212598',
		path: '/mqtt',
		protocol: 'wss' as IMqttServiceOptions['protocol'],
		keepalive: 720,
	},

	URL_LOGIN: `https://${ambienteLocal}-login.sintad.net.pe/#/auth/login`,
	URL_HOME: `https://${ambienteLocal}-login.sintad.net.pe/#/home`,
	HOST_SECURITY: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-security/api/security`,
	URL_TRANSPORTE: `https://${ambienteLocal}-transporte.sintad.net.pe`,
	HOST_TRANSPORTE: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-transporte/api`,
	URL_GUIA_REMISION: `https://${ambienteLocal}-guia-remision.sintad.net.pe`,
	HOST_GUIA_REMISION: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-guia-remision/api`,
	HOST_CONFIG: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-config/api`,
	HOST_MAESTROS: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-maestros/api`,
	HOST_MAESTROS_SYNC: `https://api-cluster-sistemas-internos.sumax.pe/${ambienteLocal}/sumax-erp-backend-maestros-sync/api`,
	HOST_REPORTES: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-reportes/api`,
	HOST_DOCTEL: `https://api-cluster.doctel.com.pe/qa/doctel/api`,

	HOST_COMPRAS: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-compras/api`,
	HOST_VENTAS: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-ventas/api`,
	HOST_BANCOS: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-bancos/api`,
	HOST_INFORMACION_OPERATIVA: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-informacion-operativa/api`,
	HOST_DOCUMENTOS_OPERATIVOS: `https://api-cluster.sumax.pe/${ambienteLocal}/sumax-erp-backend-documentos-operativos/api`,
	HOST_DMS: `https://api-cluster-dms.sumax.pe/${ambienteLocal}/sumax-dms`,
	HOST_CONTROL_CLIENTES: `https://api-cluster-sistemas-internos.sumax.pe/${ambienteLocal}/sumax-erp-backend-control-clientes/api`,
};

export function mergeEnviroments<T>(environmentBase: T, newEnviroment: Partial<T>): T {
	return {
		...environmentBase,
		...newEnviroment,
	};
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { Injectable } from '@angular/core';
import toastr from 'toastr';
import Swal, { SweetAlertResult } from 'sweetalert2';

export enum TypeMessage {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info',
}

export enum TypeActionMessage {
	REGISTRAR = 'I',
	MODIFICAR = 'U',
	ELIMINAR = 'D',
	COPIAR = 'C',
	ANULAR = 'A',
}

export enum DefaultMessageText {
	ACEPTAR = 'Aceptar',
	CONTINUAR = 'Si, Continuar',
	CANCELAR = 'No, Cancelar',
	CARCANGDO = 'Cargando...!',
	VALIDANDO = 'Validando...!',
	BEFORE_CLOSE_PAGE_TITLE = '¿Estas seguro que quieres salir del sitio web?',
	BEFORE_CLOSE_PAGE_MESSAGE = 'Es posible que los datos ingresados no se guarden.',
}
export enum ToastrPosition {
	BOTTON_RIGHT = 'toast-bottom-right',
}

@Injectable({
	providedIn: 'root',
})
export class MessageUtilService {
	message = '';

	//TOASTR
	getAlertError(codigo: string, element = '', message?: string): Toastr {
		// tslint:disable: indent
		switch (codigo) {
			case 'A001':
				this.message = `El campo ${element}  es requerido`;
				break;
			case 'A002':
				this.message = `El campo ${element} no cumple con el formato establecido`;
				break;
			case 'A003':
				this.message = `La fecha ingresada no puede ser superior a la fecha del dí­a actual`;
				break;
			case 'A004':
				this.message = `La fecha del documento no puede ser menor a la fecha del ultimo documento ingresado`;
				break;
			case '000046':
				this.message = `${message}`;
				break;
			case 'A005':
				this.message = `No ha ingresado la serie`;
				break;
			case 'A006':
				this.message = `La fecha ingresada no puede ser menor a la fecha del dí­a actual`;
				break;
			case 'A999':
				this.message = `${element}`;
				element = 'Error';
				break;
			case '500':
				this.message = `Por favor comuniquese con el soporte del Sistema`;
				break;
			default:
				this.message = element;
				break;
		}
		return this._toastrMensaje(this.message, TypeMessage.ERROR);
	}

	getAlertErrorList(errorList: string[]): Toastr {
		let errors = '';
		errorList.forEach((e, i) => {
			errors += `<p>${e}</p>`;
		});

		toastr.error(errors, `<h5 class='text-white'>Error</h5>`, {
			timeOut: 5000,
			positionClass: ToastrPosition.BOTTON_RIGHT,
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
		});

		return toastr;
	}

	getAlertWarning(mensaje: string): Toastr {
		return this._toastrMensaje(mensaje, TypeMessage.WARNING);
	}

	getAlertSuccessCrud(interfaz: string, accion: TypeActionMessage | string): Toastr {
		let accionNombre = '';
		switch (accion) {
			case TypeActionMessage.REGISTRAR:
				accionNombre = 'registrado';
				break;
			case TypeActionMessage.MODIFICAR:
				accionNombre = 'modificado';
				break;
			case TypeActionMessage.ELIMINAR:
				accionNombre = 'eliminado';
				break;
			case TypeActionMessage.COPIAR:
				accionNombre = 'copiado';
				break;
			default:
				accionNombre = accion;
				break;
		}
		return this._toastrMensaje(`${interfaz} ${accionNombre} exitosamente.`, TypeMessage.SUCCESS);
	}

	private _toastrMensaje(message: string, typeIcon: TypeMessage): Toastr {
		if (toastr != null) toastr.remove();
		toastr.options = {
			positionClass: ToastrPosition.BOTTON_RIGHT,
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
			timeOut: 8000,
		};
		if (typeIcon == TypeMessage.SUCCESS) {
			toastr[typeIcon](message);
		} else {
			toastr[typeIcon](message, `<h5 class='text-white'> ${typeIcon == TypeMessage.WARNING ? 'Advertencia' : 'Error'} </h5>`);
		}
		return toastr;
	}

	//sweealert2
	getMessageOnDelete(mensaje = '', tipo: TypeActionMessage = TypeActionMessage.ELIMINAR, focusCancel = true): Promise<SweetAlertResult<unknown>> {
		let texto = '¿Desea ';
		if (tipo == TypeActionMessage.ANULAR) {
			texto += 'anular ';
		} else {
			texto += 'eliminar ';
		}
		texto += `${mensaje}?`;
		return Swal.fire({
			title: texto,
			text: 'Esta acción no se puede deshacer',
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#0abb87',
			cancelButtonColor: '#fd397a',
			confirmButtonText: 'Si',
			cancelButtonText: 'No',
			allowOutsideClick: false,
			focusCancel: focusCancel,
		});
	}

	getMessageWarning(title = '', mensaje = '', focusCancel = true): Promise<SweetAlertResult<unknown>> {
		return Swal.fire({
			title: title,
			html: mensaje,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0abb87',
			cancelButtonColor: '#fd397a',
			confirmButtonText: 'Si',
			cancelButtonText: 'No',
			allowOutsideClick: false,
			focusCancel: focusCancel,
		});
	}

	getMessageWarningOk(title = '', mensaje = ''): Promise<SweetAlertResult<unknown>> {
		return Swal.fire({
			title: title,
			html: mensaje,
			icon: 'warning',
			confirmButtonColor: '#3085d6',
			confirmButtonText: 'OK',
			allowOutsideClick: false,
			focusCancel: true,
		});
	}

	getMessageQuestion(title: string, mensaje?: string): Promise<SweetAlertResult<unknown>> {
		return Swal.fire({
			icon: 'question',
			title: title,
			text: mensaje,
			showCancelButton: true,
			confirmButtonColor: '#0abb87',
			cancelButtonColor: '#fd397a',
			confirmButtonText: 'Sí',
			cancelButtonText: 'No',
			onOpen: () => Swal.getCancelButton()?.focus(),
		});
	}

	getMessageQuestionHtml(title: string, mensaje?: string): Promise<SweetAlertResult<unknown>> {
		let texto = `<h2 style="font-weight: 500; font-size: 1.3rem; color: #48465b;">${title}</h2>`;
		if (mensaje) texto += `<div style="font-weight: normal; font-size: 1.1rem; margin-top: 1.5rem;">${mensaje}</div>`;
		return Swal.fire({
			icon: 'question',
			html: texto,
			showCancelButton: true,
			confirmButtonColor: '#0abb87',
			cancelButtonColor: '#fd397a',
			confirmButtonText: 'Sí',
			cancelButtonText: 'No',
			onOpen: () => Swal.getCancelButton()?.focus(),
		});
	}

	getMessageSuccess(titulo: string, mensaje: string): Promise<SweetAlertResult<unknown>> {
		return Swal.fire({
			icon: 'success',
			title: titulo,
			text: mensaje,
		});
	}

	getFailMessage(mensaje: string): Promise<SweetAlertResult<unknown>> {
		return Swal.fire({
			icon: 'error',
			title: 'Error',
			text: mensaje,
		});
	}

	getMessageInfo(mensaje: string): Promise<SweetAlertResult<unknown>> {
		return Swal.fire({
			icon: 'info',
			title: 'Información',
			text: mensaje,
		});
	}

	getMessageLoading(title: string, mensaje = ''): void {
		Swal.fire({
			title: `${title}`,
			html: `<h5 class="text-dark" style="font-family: Soho Gothic Pro;">${mensaje}<h5>`,
			text: mensaje,
			allowOutsideClick: false,
		});
		return Swal.showLoading();
	}

	closeLoading(): void {
		return Swal.close();
	}

	//TABLE MESSAGE
	loadTable(): string {
		return `<span class="ag-overlay-loading-top d-flex align-items-center
		border-0 bg-white shadow p-4 text-info" style="height: 20px;">
		<img src="assets/media-demo/loading.gif"
		height="20" width="25" class="me-2"><strong>Cargando registros</strong></span>`;
	}

	loadTableIsBlank(): string {
		return `<span class="ag-overlay-loading-center border-0 bg-transparent">
		No existen Documentos Relacionados ...</span>`;
	}

	loadClassDisplayFlex(justifyContent: string, alignItems: string): string {
		return `d-flex justify-content-${justifyContent} align-items-${alignItems}`;
	}

	getTableWarning(datos: any[], mensaje?: string, title = 'Advertencia'): Promise<SweetAlertResult<unknown>> | undefined {
		function escapeHtml(unsafe: any): string {
			return String(unsafe).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
		}

		function makeTableHtml(myArray: string[]): string {
			let result = "<table class='table table-striped'>";
			for (let i = 0; i < myArray.length; i++) {
				result += '<tr>';
				for (let j = 0; j < myArray[i].length; j++) {
					const k = escapeHtml(myArray[i][j]);
					result += "<td style='text-align: left; font-size: 12px;'>" + k + '</td>';
				}
				result += '</tr>';
			}
			result += '</table>';
			return result;
		}
		if (datos.length > 0) {
			Object.keys(datos[0]).length;
			return Swal.fire({
				title: `<h5 style='font-size: 1.6rem;'>${title}</h5>`,
				html: `<h5 class="text-dark" style="font-family: Soho Gothic Pro; text-align: left; font-size: 1.1rem;">${mensaje}<h5>
				<div #table-clientes style="max-height: 16em !important; overflow-y: scroll !important;
				width: 100% !important;">${makeTableHtml(datos)}</div>`,
				showCancelButton: true,
				confirmButtonColor: '#0abb87',
				cancelButtonColor: '#5867dd',
				confirmButtonText: 'Aceptar',
				cancelButtonText: 'Exportar',
				allowOutsideClick: false,
				focusConfirm: true,
			});
		}
		return;
	}

	getMessageQuestionRewrite(datos: any[], mensaje: string): Promise<SweetAlertResult<unknown>> {
		function makeTableHtml(myArray: string[]): string {
			let result = "<table class='table table-striped'>";
			for (let i = 0; i < myArray.length; i++) {
				result += '<tr>';
				result += "<td style='text-align: left; font-size: 12px; padding-left: 0.5px; padding-right: 0.5px'>" + myArray[i] + '</td>';
				result += '</tr>';
			}
			result += '</table>';
			return result;
		}

		let htmlMessage = '';
		if (datos.length == 1) {
			htmlMessage = `${mensaje}: <br> ${datos[0]} <br> ¿Desea reemplazarlo o mantener ambos?`;
		} else {
			htmlMessage = `<div style="text-align: left; margin-bottom: 10px">${mensaje}</div>
			<div #table-clientes style="max-height: 12em !important; overflow-y: scroll !important;
			width: 100% !important;">${makeTableHtml(datos)}</div> <div>¿Desea reemplazarlos o mantener ambos?</div>`;
		}
		return Swal.fire({
			html: htmlMessage,
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#0abb87',
			cancelButtonColor: '#fd397a',
			confirmButtonText: 'Reemplazar',
			cancelButtonText: 'Mantener ambos',
			allowOutsideClick: true,
			focusCancel: true,
		});
	}
}
